<template>
  <div id="modelView" class="container" style="position: absolute">
    <div id="obv-view1" style="width:100%;height:100%;position: relative;"></div>
    <!-- <button id="getViewPointInfo" class="uk-button uk-button-primary" @click="c01">获取当前视点</button> -->
  </div>
</template> 
<script>
import { authentication } from "@/js/common";
import { ViewerInitializer } from "@/js/obv-init";
import axios from 'axios';
export default {
  name: "modelView",
  data() {
    return {
      viewPoint:'',
      // viewPoint:{"aspect":1.8927911275415896,"fov":39.84757633485368,"left":-1024,"right":1024,"top":541,"bottom":-541,"zoom":4.9618608861655105,"isPerspective":false,"pivotPoint":{"x":-88.73553370357732,"y":38.5632855085011,"z":30.762381629467736},"position":{"x":154.52092566442994,"y":161.75360981360245,"z":156.78000211084793},"target":{"x":-88.73553370357732,"y":38.5632855085011,"z":30.762381629467736},"up":{"x":0,"y":0,"z":1}},
      objectNodes: {},
      rootId: "",
      type: "",
      FIRST_RUN: true,
      V3dGeometryLoadedEvent: false,
      V3dPropertiesLoadedEvent: false,
      V3dModelTreeLoadedEvent: false,
      V3dGeometryLoadedEventList: [],
      V3dPropertiesLoadedEventList: [],
      V3dModelTreeLoadedEventList: [],
      obvShow2: false,
      obvShow: true,
      linkage: false,
      markedNodeIds: [],
      obvApiWrapper: {},
      modelIdx: 1,
      modelList: [],
      applicationOptions: {
        // 配置 OBV 服务端（BIMServer）API 服务的 origin，这个适合于私有部署的用户使用
        // 如果不配置，则默认为 'http://103.48.232.166:996'
        serviceConfig: {
          origin: "https://api.cloud.pkpm.cn",
          apiContextPath: "/bimserver/viewing/v3"
        },
        getAccessToken: this.getAccessToken,
        refreshAccessToken: this.refreshAccessToken
      },
      addinConfig: [
        {
          id: "OBVAddins.Walk",
          noButton: false
        },
        {
          id: "OBVAddins.Measure",
          noButton: false
        },
        {
          id: "OBVAddins.Marker",
          noButton: false
        },
        {
          id: "OBVAddins.Section",
          noButton: false
        }
      ]
    };
  },
  props: {
    mtype:{ 
      default:0
    }
  },
  created() {},
  mounted() {
    console.log('mounted') 
    window.obvApiOne = ''
  },
  beforeDestroy() {
    this.unnitObv();
    console.log("组件已销毁");
  },
  methods: {
    InitRefinedModelTree(_obvApi, modelIndex, fileName, callback) {
        var model = _obvApi.getModelById(modelIndex);
        var modelTree = model.getModelTree();
        var pending = 0;
        var total = 0;
        var errorCount = 0;
        let me = this;
        console.log(modelTree);
        modelTree.traverseNode(
          modelTree.getRootId(),
          function(dbId) {
            pending++;
            _obvApi.getProperties(
              { modelId: modelIndex, dbId: dbId },
              function(p) {
                pending--;
                total++;
                me.BindRefinedModelTreeInfo(p, model, fileName);
                console.log(pending)
                if (pending === 0) {
                  callback(modelIndex);
                }
              },
              function(error) {
                console.log(error);
                pending--;
                errorCount++;
                if (pending === 0) {
                  console.log(
                    "done!, total is: ",
                    total,
                    "errorCount is: ",
                    errorCount
                  );
                }
              }
            );
          },
          true
        );
    },
    BindRefinedModelTreeInfo(p, m, fileName) {
      // console.log(p)
        let me = this;
        var dbId = p.dbId;
        var modelIndex = m.id;
        var urn = m.documentUrn;
        //TT:type  TP:partition  TC:component code
        var item = {
          modelId: modelIndex,
          id: dbId,
          pId: 0,
          name: "Name",
          tId: "",
          guid: "",
          nodeInfo: { TT: "", TP: "", TC: "" },
          nodeIds: [{ modelId: modelIndex, dbId: dbId }]
        };

        var assembly_prefix = "";
        var assembly_start_number = "";
        var teklaCodeTemp = "";
        $.each(p.properties, function(index, prop) {
          switch (prop.displayName) {
            case "Assembly/Cast unit Mark":
              item.nodeInfo.TC = prop.displayValue;
              break;
            case "ASSEMBLY_SERIAL_NUMBER":
              assembly_start_number = prop.displayValue;
              break;
            case "ASSEMBLY_PREFIX":
              assembly_prefix = prop.displayValue;
              break;
            case "Assembly mark":
              item.nodeInfo.TC = prop.displayValue;
              break;
            case "ASSEMBLY_POS":
              item.nodeInfo.TC = prop.displayValue;
              break;
            case "ID":
              item.tId = prop.displayValue;
              break;
            case "GUID":
              item.guid = prop.displayValue;
              break;
            default:
              break;
          }
        });
        if (!item.nodeInfo.TC) {
          item.nodeInfo.TC = assembly_prefix + assembly_start_number;
        }
        if (item.nodeInfo.TC.indexOf("?") != -1) {
          item.nodeInfo.TC = item.nodeInfo.TC.substring(
            0,
            item.nodeInfo.TC.length - 3
          );
        }
        var isHave = false;
        if (item.nodeInfo.TC == "") {
          isHave = true;
        } else {
          $.each(me.objectNodes[modelIndex]._treeItem, function(index, _item) {
            if (_item.nodeInfo.TC == item.nodeInfo.TC) {
              _item.nodeIds.push({ modelId: modelIndex, dbId: dbId });
              isHave = true;
              return false;
            }
          });
        }
        if (!isHave) {
          var treeNode = {
            modelId: modelIndex,
            dbId: dbId,
            urn: me.modelList[0].urn,
            id: item.tId,
            guid: item.guid,
            pId: 0,
            name: item.nodeInfo.TC
          };
          treeNode.name = item.nodeInfo.TC;
          treeNode.pId = fileName;
          me.objectNodes[modelIndex]._treeItem.push(item);
          if (item) {
            if (me.FIRST_RUN) {
              let treeNodeFirst = {
                modelId: modelIndex,
                dbId: fileName,
                urn: me.modelList[0].urn,
                id: fileName,
                guid: fileName,
                pId: fileName,
                name: fileName
              };
              me.objectNodes[modelIndex].treeItem.push(treeNodeFirst);
              me.objectNodes[modelIndex].treeItem.push(treeNode);
              me.FIRST_RUN = false;
            } else {
              me.objectNodes[modelIndex].treeItem.push(treeNode);
            }
          }
        }
    },
    c01(){
      this.viewPoint = obvApiOne.getViewPointInfo();
      console.log(JSON.stringify(this.viewPoint))
    },
    modelInit(urn, type) {
      this.type = type;
      let that = this;
      console.log("加载模型");
      this.initializeViewer("obv-view1", urn, this.getAccessToken, function(
        obvApi,
        application,
        modelsArr
      ) {
        let modelList = [];
        modelsArr.forEach((item, i) => {
          var obj = {};
          if (item.model.documentUrn) {
            obj.urn = item.model.documentUrn;
          }
          if (item.model.id) {
            obj.modelIndex = item.model.id;
          }
          modelList.push(obj);
        }); 
        that.modelList = modelList;
        if (modelList.length > 0) {
          that.$emit("LoadmodelList", modelList);
        }
        //设置透明度
        OBV.SettingsController.instance.defaultSettings.exposure = 0.5;
        //关闭x光模式
        OBV.SettingsController.showLineSet = false;
        window.obvApiOne = obvApi;
        let number = 0;  
        obvApiOne.addEventListener(
          OBV.ViewerEventTypes.V3dGeometryLoadedEvent,
          function(event) {
             obvApiOne.viewFront();
             if(that.viewPoint && !that.mtype){
               obvApiOne.setViewPointInfo(that.viewPoint)
             }
             that.$emit("loaded",obvApiOne.getModelIds()[0],obvApiOne);
          }
        );
        obvApiOne.addEventListener(
          OBV.ViewerEventTypes.V3dPropertiesLoadedEvent,
          function(event) { 
            that.V3dPropertiesLoadedEventList.push(1); 
            if (modelList.length == 1) {
              if (
                that.V3dGeometryLoadedEventList == modelList.length &&
                that.V3dPropertiesLoadedEventList.length == modelList.length &&
                that.V3dModelTreeLoadedEventList == modelList.length
              ) { 
                obvApiOne.viewFront();
              }
            } else {
              if (
                that.V3dGeometryLoadedEventList.length > 1 &&
                that.V3dPropertiesLoadedEvent &&
                that.V3dModelTreeLoadedEvent
              ) { 
                obvApiOne.viewFront();
              }
            }
          }
        );
        obvApiOne.addEventListener(
          OBV.ViewerEventTypes.V3dModelTreeLoadedEvent,
          function(event) { 
            that.V3dModelTreeLoadedEvent = true;
            that.V3dModelTreeLoadedEventList.push(1); 
            if (modelList.length == 1) {
              if (
                that.V3dGeometryLoadedEventList == modelList.length &&
                that.V3dPropertiesLoadedEventList.length == modelList.length &&
                that.V3dModelTreeLoadedEventList == modelList.length
              ) { 
                obvApiOne.viewFront();
              }
            } else {
              if (
                that.V3dGeometryLoadedEventList.length > 1 &&
                that.V3dPropertiesLoadedEvent &&
                that.V3dModelTreeLoadedEvent
              ) { 
                obvApiOne.viewFront();
              }
            }
          }
        );
        obvApiOne.addEventListener(
          OBV.ViewerEventTypes.SelectionChangedEvent,
          event => {
            let selection = event.nodeIdArray;
            if(selection && selection[0]){
              that.$emit("selection", selection[0]);
            }
          }
        );
        obvApiOne.addEventListener(
          OBV.ViewerEventTypes.PropertiesLoadedEvent,
          event => {
            // let modelIndex = obvApiOne.getModelIds()[0];
            // that.InitRefinedModelTree(obvApiOne,modelIndex, "", function(
            //   index
            // ) {
            //   console.log(that.objectNodes)
            //   that.$emit(
            //     "obvallloaded",
            //     {
            //       modelIndex:modelIndex,
            //       index:index,
            //       nodes:that.objectNodes[modelIndex]
            //     }
            //   );
            // });
          }
        );
        // 此例实现在视图区右键单击时添加自定义环境菜单项和在选中某个对象时添加自定义菜单项
        obvApiOne.registerContextMenuCallback("MyMenuItems", function(
          menu,
          status
        ) {
          if (status.hasSelected) {
            //在选中某个对象时添加自定义菜单项
            menu.push({
              title: "查看精细化模型",
              onSelect: function() {
                //传递鼠标右键添加批注事件
                that.$emit("lookCareModel", "lookCareModel");
              }
            });
          } else {
          }
        });
        // //在主视图的工具条中增加工具条
        // var mainToolbar = obvApiOne.getToolbar(false);
        // toolbar = mainToolbar.getControlById("customized-button");
        // if (toolbar == null) {
        //   var mainToolbar = obvApiOne.getToolbar(true);
        //   toolbar = new OBV.Oui.Controls.ControlGroup("customized-button2");
        //   var button = new OBV.Oui.Controls.Button("my-button");
        //   button.iconElement.className += " iconfont icon-listAjust";
        //   button.setToolTip("构件模式");
        //   button.onClick = function(e) {
        //     $("#customized-button2")
        //       .children(".obv-control")
        //       .addClass("active");
        //     $("#customized-button3")
        //       .children(".obv-control")
        //       .removeClass("active");
        //     that.$emit("listType", true);
        //   };
        //   toolbar.addControl(button);
        //   mainToolbar.addControl(toolbar);
        //   $("#customized-button2")
        //     .children(".obv-control")
        //     .addClass("active");
        // }
        // //在主视图的工具条中增加工具条
        // var mainToolbar = obvApiOne.getToolbar(false);
        // toolbar = mainToolbar.getControlById("customized-button");
        // if (toolbar == null) {
        //   var mainToolbar = obvApiOne.getToolbar(true);
        //   toolbar = new OBV.Oui.Controls.ControlGroup("customized-button3");
        //   var button = new OBV.Oui.Controls.Button("my-button");
        //   button.iconElement.className += " iconfont icon-list";

        //   button.setToolTip("零件模式");
        //   button.onClick = function(e) {
        //     $("#customized-button3")
        //       .children(".obv-control")
        //       .addClass("active");
        //     $("#customized-button2")
        //       .children(".obv-control")
        //       .removeClass("active");
        //     that.$emit("listType", false);
        //   };
        //   toolbar.addControl(button);
        //   mainToolbar.addControl(toolbar);
        // }
      });
    },
    loadModel(containerId, urnList, getAccessToken, callback) { 
      var viewerContainer = document.getElementById(containerId);
      var options = {
        config3d: {
          addinConfigs: this.addinConfig
        },
        applicationOptions: {
          getAccessToken: getAccessToken,
          refreshToken: getAccessToken,
          serviceConfig: {
            origin: "https://api.cloud.pkpm.cn",
            apiContextPath: "/bimserver/viewing/v3"
          }
        },
        viewerContainer: viewerContainer,
        viewerModelItemList: this.formatViewerModel1(urnList)
      };
      new ViewerInitializer()
        .loadViewModel(options)
        .then(({ obvApi, application, modelsArr }) => {
          callback && callback(obvApi, application, modelsArr);
        });
    },
    initializeViewer(containerId, urnList, getAccessToken, callback) {
      // urnlist 的格式 ['urn:bimbox.object:viewing_bucket/rvt-model', 'urn:----------'] 不需要编码
      this.loadModel(
        containerId,
        urnList,
        getAccessToken,
        (obvApi, application, modelsArr) => {
          callback && callback(obvApi, application, modelsArr);
        }
      );
    },
    // 格式化 urn
    formatViewerModel1(urnList) {
      const list = [];
      urnList.forEach((urnStr, index) => {
        if (urnStr.split("^").length > 1) {
          if (urnStr.split("^")[0] == "22冶") {
            list.push({
              documentUrn: urnStr.split("^")[1],
              guid: "",
              offset: { x: 0, y: 413500, z: 0 }
            });
          } else {
            list.push({
              documentUrn: urnStr.split("^")[1],
              guid: "",
              offset: { x: 0, y: 0, z: 0 }
            });
          }
        } else {
          list.push({
            documentUrn: urnStr,
            guid: "",
            offset: { x: 0, y: 0, z: 0 }
          });
        }
      });
      return list;
    }, 
    load_event() {
      // console.log(authentication);
    },
    //三维的注销组件
    unnitObv() { 
      if (obvApiOne && obvApiOne.uninitializeViewer) {
        obvApiOne.uninitializeViewer();
        window.obvApiOne = null;
        console.log("模型已经销毁");
      }
    },
    //显示指定对象
    showObj(obj) {
      obvApiOne.show(obj);
    },
    //隐藏对象
    hideObj(obj) {
      obvApiOne.hide(obj);
    },
    //选择指定对象
    selectObj(obj) {
      // console.log(obj);
      obvApiOne.select(obj);
    },
    deselectObj(obj) {
      // console.log(obj);
      obvApiOne.deselect(obj);
    },
    //获取访问令牌
    getAccessToken(cb) {
      const that = this;
      // 组装完整的 API 路径
      const url = authentication.baseUrl + "/bimserver/auth/oauth/token";
      // 创建 form data
      const params = {
        client_id: authentication.credentials.client_id,
        client_secret: authentication.credentials.client_secret,
        grant_type: authentication.credentials.grant_type
      };
      axios({
          url: url,
          params: params,
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          cb(res.data.access_token, res.data.expires_in);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.container {
  width: 100%;
  height: 100%;
}
#v3d-viewer{
  display:none;
}
#modelView {
  position: absolute;
  // &:deep(.obv-control){
  //   display: none;
  // }
  .pkpm-obv-viewer .obv-toolbar-left{
    // display: none;
    position: absolute;
    left: 25%;
  }
  .obv-view-cube-container{
    display: none;
  }
  #obv-view1 {
    
    canvas {
      width: 100% !important;
      #obv-toolbar-home-view-button,
      #obv-toolbar-perspective-view-button,
      #obv-toolbar-pan-tool,
      #toolbar-walkTool,
      #toolbar-model-structure-tool,
      #toolbar-properties-tool {
        display: none;
      }
      .marker-annotation {
        display: none !important;
      }
      .bimbox-control-group {
        background-color: rgba(189, 198, 207, 0.8);
      }
      .bimbox-button.active .bimbox-button-icon,
      .bimbox-button:hover > .bimbox-button-icon {
        color: #3b5f90;
      }
      .marker-anchor {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        line-height: 18px;
        font-size: 12px;
        color: rgb(255, 255, 255);
        font-weight: 400;
        text-align: center;
        background: rgb(211, 82, 82);
        border: 3px solid rgba(240, 198, 198, 0.8);
      }
      .anchorColor_1 {
        background: rgb(211, 82, 82);
        border: 3px solid rgba(240, 198, 198, 0.8);
      }
      .anchorColor_2 {
        background: rgb(228, 122, 25);
        border: 3px solid rgba(245, 231, 161, 0.8);
      }
      .anchorColor_3 {
        background: rgb(74, 144, 226);
        border: 3px solid rgba(180, 208, 240, 0.8);
      }
      .anchorColor_4 {
        background: rgb(66, 183, 91);
        border: 3px solid rgba(186, 252, 200, 0.8);
      }
      .anchorColor_auto1 {
        background: rgb(211, 82, 82);
        border-radius: 0;
      }
      .anchorColor_auto2 {
        background: rgb(228, 122, 25);
        border-radius: 0;
      }
      .anchorColor_auto3 {
        background: rgb(74, 144, 226);
        border-radius: 0;
      }
      .anchorColor_auto4 {
        background: rgb(66, 183, 91);
        border-radius: 0;
      }
    }
  }
}
</style>
