<template>
  <div class="addModel">
    <div class="addModel-wrap">
      <div class="addModel-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addModel-content">
        <div class="addModel-input">
          <span>模型所属模块</span>
          <div>
            <el-select v-model="belonging"
                       class="m-2"
                       placeholder="请选择"
                       size="large">
              <el-option v-for="item in options"
                         :key="item.ID"
                         :label="item.Name"
                         :value="item.ID">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="addModel-input">
          <span>模型名称</span>
          <div>
            <el-input v-model="inputs1"
                      placeholder="请输入名称"></el-input>
          </div>
        </div>
      </div>

      <div class="addModel-add clearfix"
           v-if="FileType == 1">
        <div class="addModel-btn">
          <img src="@/assets/images/common/add.png"
               alt="" />
          <span>{{ fname }}</span>
          <input type="file"
                 @change="modelUpload"
                 ref="upinput" />
        </div>
      </div>
      <div class="addModel-add clearfix"
           v-if="FileType == 2">
        <el-upload class="picture-card"
                   :multiple="false"
                   accept="image/jpg,image/jpeg,image/png"
                   :action="
            baseUrl +
            `/Intelligence_Building_API/WeatherForecast/SavePictureList?type=7`
          "
                   :on-success="successfn"
                   :class="{ hiddenBtn: filelist.length >= 1 }"
                   :file-list="filelist"
                   list-type="picture-card"
                   :on-remove="handleRemove"
                   :limit="1">
          <img src="@/assets/images/common/cicon09.png"
               alt=""
               class="up-icon" />
        </el-upload>
      </div>

      <div class="progress-c">
        <div class="all-l"
             v-if="projectFile">
          <div class="d-text"
               v-if="pro >= 100">{{ dtext }}</div>
          <div class="d-text"
               v-else>{{ pro + "%" }}</div>
          <div class="done-l"
               :style="{ width: pro + '%' }"></div>
        </div>
      </div>
      <div class="radio-c">
        <el-radio-group v-model="FileType"
                        @change="mtchange">
          <el-radio label="1"
                    size="large">BIM模型</el-radio>
          <el-radio label="2"
                    size="large">2D图</el-radio>
        </el-radio-group>
        <!-- <el-radio v-model="FileType" label="1" size="large">BIM模型</el-radio>
          <el-radio v-model="FileType" label="2" size="large">BIM模型</el-radio> -->
      </div>
      <div class="addModel-bottom">
        <button @click="closefn"
                class="def">取消</button>
        <button @click="AddExtractApplyfn"
                :class="{ disable: !belonging || !urn || !inputs1 }">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { uploadModel } from "@/js/uploadModel";
import {
  GeteModelSubordinateModuleCategoryList,
  AddModelManagement,
} from "@/js/modelApi";
import SparkMD5 from "spark-md5";
import { baseUrl } from "@/js/util.js";
export default {
  props: {
    addfalse: Boolean,
    addtitle: String,
    editdata: Object,
    oft: Object,
  },
  setup (props, { emit }) {
    const state = reactive({
      baseUrl: baseUrl,
      addtitle: "",
      options: [],
      belonging: "",
      upinput: "",
      oft: {},
      pro: 0,
      dtext: "正在处理中,请稍后.....",
      fname: "选择本地文件",
      projectFile: "",
      urn: "",
      inputs1: "", // 模型名称
      FileType: "1", // [1,2] [BIM,图片]
      filelist: [],
      urn: null,
    });
    const closefn = () => {
      emit("addfalsefn");
    };
    const cdata = getStorage("bscdata");
    const calculate = (file, callBack) => {
      let fileReader = new FileReader(),
        blobSlice =
          File.prototype.mozSlice ||
          File.prototype.webkitSlice ||
          File.prototype.slice,
        chunkSize = 2097152,
        // read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5();

      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result); // append binary string
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          callBack(spark.end());
        }
      };

      function loadNext () {
        let start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
      }

      loadNext();
    };
    const procb = (pro) => {
      state.pro = pro;
    };
    const resetFileData = () => {
      state.pro = 0;
      state.dtext = "正在处理中,请稍后.....";
      state.fname = "选择本地文件";
      state.projectFile = "";
      state.urn = "";
      state.filelist = [];
    };
    const GSMC = () => {
      GeteModelSubordinateModuleCategoryList({ ProjectID: cdata.pid }).then(
        (res) => {
          console.log(res);
          let { Code, Data, Message } = res.data;
          if (Code == 1 && Data) {
            state.options = Data;
          } else {
            ElMessage({
              showClose: true,
              message: Message,
              type: "error",
            });
          }
        }
      );
    };
    const methods = {
      mtchange: () => {
        console.log(state.FileType);
        resetFileData();
      },
      handleRemove: (file, fileList) => {
        // 删除图片
        state.filelist = fileList;
        state.urn = null;
      },
      // 上传图片
      successfn: (response, file, fileList) => {
        // 图片上传成功
        console.log(response, file, fileList);
        state.filelist = fileList;
        state.urn = response.join(",");
      },
      beforeUpload: (file) => {
        const is1M = file.size / 1024 / 1024 < 3; // 限制小于3M
        if (!is1M) {
          ElMessage({
            showClose: true,
            message: "大小不可超过3M",
            type: "error",
          });
        }
        return is1M;
      },
      modelUpload: (e) => {
        resetFileData();
        state.projectFile = state.upinput.files[0];
        let file = state.upinput.files[0];
        console.log(file);
        state.fname = file.name;
        calculate(file, (md5) => {
          file.md5 = md5;
          console.log(file);
          uploadModel(
            file,
            (urn) => {
              console.log(urn);
              state.dtext = "上传完成";
              state.urn = urn;
            },
            state.oft,
            procb
          );
        });
      },
      AddExtractApplyfn: () => {
        console.log(state.urn, state.belonging);
        if (state.urn && state.belonging && state.inputs1) {
          let datas = {
            imgUrl: state.urn,
            modelSubordinateModuleCategory: state.belonging,
            user: cdata.uid,
            projectID: cdata.pid,
            Name: state.inputs1,
            FileType: state.FileType
          };
          AddModelManagement(datas).then((res) => {
            console.log(res);
            let { Code, Message } = res.data;
            if (Code == 1) {
              emit("GeteModelManagementList");
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
              emit("addfalsefn");
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      },
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      state.oft = props.oft;
      console.log(props.editdata);
      if (state.addtitle == "编辑BIM模型") {
      }
      GSMC();
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addModel {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  &:deep(.addModel-wrap) {
    .el-upload-list--picture-card {
      .el-upload-list__item {
        width: 81px;
        height: 63px;
        border-radius: 0;
      }
      .el-upload-list__item-actions:hover span.el-upload-list__item-preview {
        display: none;
      }
    }
    .el-upload--picture-card {
      width: 81px;
      height: 63px;
      position: relative;
      background: #f3f3f3;
      border: 1px dashed #999999;
      border-radius: 0;
      .up-icon {
        width: 34px;
        height: 35px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      i {
        position: absolute;
        right: 12px;
        bottom: 8px;
        font-size: 13px;
        color: #999999;
        display: inline-block;
        height: 20px;
        line-height: 20px;
      }
    }

    .hiddenBtn {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
  .progress-c {
    height: 50px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .all-l {
      height: 20px;
      background: #dfdfdf;
      width: 500px;
      position: relative;
      .d-text {
        text-align: center;
        font-size: 14px;
        color: #fff;
        position: absolute;
        width: 100%;
        z-index: 9;
      }
      .done-l {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        background: #0083ff;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
  .addModel-wrap {
    width: 576px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .radio-c {
      text-align: CENTER;
      padding: 0 0 20px;
    }
    .addModel-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addModel-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addModel-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      span {
        display: inline-block;
        padding-right: 11px;
        box-sizing: border-box;
      }
      div {
        width: 187px;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addModel-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addModel-add {
    margin-left: 116px;
    margin-bottom: 0;

    .addModel-btn {
      padding: 0 10px;
      min-width: 128px;
      max-width: 300px;
      height: 32px;
      background: rgba(0, 180, 255, 0.1);
      border-radius: 4px;
      border: 1px solid #0083ff;
      font-size: 12px;
      color: #0083ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      float: left;
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        box-sizing: border-box;
      }
    }
  }

  .addModel-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #adbaae;
      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;
      background: #027aff;
      color: #fff;
      &.def {
        background: #fff;
        color: #adbaae;
        cursor: default;
      }
      &.disable {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}
</style>