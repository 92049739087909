import axios from 'axios';
let token = '';
const viewing_bucket = "SmartConstructionSite";
let OBV_FILE_TYPE = [];
let authentication={    //开建申请
  credentials:{
      client_id:'1etAvb3tgMFk30uf0abv9Rt',   
      client_secret:'1uHX5q3tgMFk2y1L6g40gY27', 
      grant_type:'client_credentials'
  },
  baseUrl:'https://api.cloud.pkpm.cn',
}
//存储每个文件对应的远程服务器上传信息
let file_md5_dic = {};


export const GetConvertTypes = (t)=> {
  token = t;
  return axios({
      method:'get',
      url:`${authentication.baseUrl}/bimserver/translation/v3/convertertypes`,
      responseType:'json',
      params:{},
      headers: {'Authorization': `Bearer${token}`,"Content-type":'application/json'},
  })
}

// 创建bucket 相当于文件夹
export const createBucket = (t)=> {
  token = t;
  console.log(t,31)
  return axios({
      method:'post',
      url:`${authentication.baseUrl}/bimserver/storage/v3/buckets/${viewing_bucket}`,
      data:{},
      headers: {'Authorization': `Bearer${token}`,"Content-type":'application/json'},
  })
}

export const getAccessToken = ()=> {
  return axios({
      method:'post',
      url:`${authentication.baseUrl}/bimserver/auth/oauth/token`,
      params: {
        client_id:authentication.credentials.client_id,
        client_secret:authentication.credentials.client_secret,
        grant_type:authentication.credentials.grant_type
      },
      headers: {"Content-type":'application/x-www-form-urlencoded'},
  })
}
//转换模型接口 第三方后台自动转换
const convertOnline = (filename, bucketName, objectKey, job_type, fileId)=> {
  let convert_job_type = "";
  job_type = job_type.toLocaleLowerCase();
  if (OBV_FILE_TYPE && OBV_FILE_TYPE[job_type]) {
      convert_job_type = OBV_FILE_TYPE[job_type].default;
  }
  else {
      alert("获取转换模型类型失败!")
      return;
  }
  var data = {
      seed: 'urn:bimbox.object:' + bucketName + '/' + objectKey,
      job_type: convert_job_type,
  }

  axios({
      method:'post',
      url:`${authentication.baseUrl}/bimserver/translation/v3/jobs`,
      data: JSON.stringify(data),
      headers: {'Authorization': `Bearer${token}`,"Content-type":'application/json'},
      responseType:'json',
  }).then((res)=>{
   
  }).catch((error)=>{
    alert(filename + "转换失败:")
  })
}


const getConvertStatus = (filename, bucket, objectName, ext, type, urn, callback, version, fileId, cb)=> {
  var newObjectKey = objectName;
  axios({
      method:'get',
      url:`${authentication.baseUrl}/bimserver/translation/v3/jobs?urn=urn:bimbox.object:${bucket}/${newObjectKey}`,
      params: {},
      headers: {'Authorization': `Bearer${token}`,"Content-type":'application/json'},
      responseType:'json',
  }).then((res)=>{
    if (res.data.state === 'complete') {
        cb(urn);
        if (type == "create") {
            if (confirm("模型已经生成成功，是否重新生成？")) {
                callback(filename, bucket, objectName, ext, fileId)
            }
        }
        else if (type == "search") {

        }
      }
      else if (res.data.state === 'aborted') {
          if (type == "create") {
              callback(filename, bucket, objectName, ext, fileId)
          }
          else if (type == "search") {
              alert("模型生成失败，请重新生成模型后浏览!")
          }
      }
      else if (res.data.state === 'busy') {
          alert('当前转换状态： 模型转换中，请耐心等待~')
      }
      else if (res.data.state === 'queued') {
          alert('当前转换状态： 模型转换排队中，请耐心等待~')
      }
      else {
          if (type == "create") {
              if (confirm("当前转换状态： " + res.data.state + "，是否重新生成？")) {
                  callback(filename, bucket, objectName, ext, fileId)
              }
          }
          else if (type == "search") {
              alert("当前转换状态： " + res.data.state + "，模型无法预览，请尝试重新生成模型!")
          }
      }
  }).catch((error)=>{
    cb(urn,error)
    if (error.response.data.code == "NoSuchJob") {
        if (type == "create") {
            callback(filename, bucket, objectName, ext, fileId)
        }
        else if (type == "search") {
            alert("模型不存在，请先生成模型！")
        }
    }
    else {
        alert(error.responseJSON.message)
    }
  })
}

//获取文件详情并生成模型
const getInfoAndGetStatus = (filename, viewing_bucket, objectName, urn, md5, ext, type, version, fileId, cb)=> {
  ext = ext.toLocaleLowerCase();
  if (type == "create")
      getConvertStatus(filename, viewing_bucket, objectName, ext, type, urn, convertOnline, '', fileId, cb)
  else if (type == "search")
      getConvertStatus(filename, viewing_bucket, objectName, ext, type, urn, function () { }, version, cb)
}
//保存到BIM文件服务器
const saveFile = (file, cb,procb)=> {
  let data = file_md5_dic[file.md5]["convertObj"]
  let url = authentication.baseUrl + file_md5_dic[file.md5]["uploadUrlData"].uploadUrl
  let signature = file_md5_dic[file.md5]["uploadUrlData"].signature
  axios({
      method:'post',
      url:url,
      data: data,
      headers: {'Authorization': `Bearer${token}`,"Content-type":'application/octet-stream',"x-bimserver-upload-url":signature},
      responseType:'json',
      onUploadProgress: progressEvent => {
        let pro = (progressEvent.loaded / progressEvent.total * 100 | 0);
        console.log(pro)
        procb(pro)
      }
  }).then((res)=>{
    let objectName = file_md5_dic[file.md5]["ObjectKey"]
    let urn = 'urn:bimbox.object:' + viewing_bucket + '/' + objectName
    getInfoAndGetStatus(file.name, viewing_bucket, objectName, urn, file.md5, file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length), "create", '', file.id, cb)
  }).catch((error)=>{
    alert(error)
  })


  // $.ajax({
  //     type: 'POST',
  //     url: url,
  //     data: data,
  //     xhr: function () {
  //         myXhr = $.ajaxSettings.xhr();
  //         if (myXhr.upload) {
  //             myXhr.upload.addEventListener('procbgress', function (e) {
  //                 if (e.lengthComputable) {
  //                     var percent = Math.floor(e.loaded / e.total * 100);
  //                     if (percent <= 100) {
  //                         $("#procbgress_div").show()
  //                         $("#procbgress_bar").css('width', percent + '%');
  //                         $("#procbgress_bar").html(percent + '%');
  //                     }
  //                     if (percent >= 100) {
  //                         $("#procbgress_bar").html("正在处理中,请稍后.....");
  //                     }
  //                 }
  //             }, false);
  //         }
  //         return myXhr;
  //     },
  //     beforeSend: function (request) {
  //         request.setRequestHeader("Authorization", 'Bearer ' + token);
  //         request.setRequestHeader("x-bimserver-upload-url", signature);
  //         request.setRequestHeader("Content-Type", "application/octet-stream");
  //     },
  //     procbcessData: false,
  //     success: function (res) {
  //         let objectName = file_md5_dic[file.md5]["ObjectKey"]
  //         let urn = 'urn:bimbox.object:' + viewing_bucket + '/' + objectName
  //         getInfoAndGetStatus(file.name, viewing_bucket, objectName, urn, file.md5, file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length), "create", '', file.id, cb)
  //     },
  //     error: function (err) {
  //         console.log(err)
  //     }
  // })
}


const getUploadUrl = (file, cb,procb)=> {
  let newObjectKey = file_md5_dic[file.md5]["ObjectKey"];
  axios({
      method:'get',
      url:`${authentication.baseUrl}/bimserver/storage/v3/uploadUrl`,
      params:{
        type:7,
        expire:60000,
        bucketKey:viewing_bucket,
        objectKey:newObjectKey
      },
      headers: {'Authorization': `Bearer${token}`,'Content-Type':"multipart/form-data"},
  }).then((res)=>{
    file_md5_dic[file.md5]["uploadUrlData"] = res.data.data;
    saveFile(file, cb,procb)
  }).catch((error)=>{
    alert(error)
  })
}
const convertFile = (file, cb,procb)=> {
  let blob = file//.source.source
  if (!blob) {
      alert("未找到文件,请从新上传~")
      return false
  }
  var reader = new FileReader()
  reader.onload = function (e) {
      file_md5_dic[file.md5]["convertObj"] = e.target.result
      getUploadUrl(file, cb,procb)
  }
  reader.readAsArrayBuffer(blob)
}
export const uploadModel = (file, cb, oft,procb) => {
  OBV_FILE_TYPE = [];
  OBV_FILE_TYPE = oft;
  console.log(OBV_FILE_TYPE)
  token = token;
  file_md5_dic[file.md5] = {}
  file_md5_dic[file.md5]["ObjectKey"] = "ObjectKey_" + new Date().getTime()
  file_md5_dic[file.md5]["ext"] = file.ext
  convertFile(file, cb,procb)
}