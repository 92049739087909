<template>
  <div id="modelView-c">
    <div class="Implement-add">
      <div class="adds" :class="{ disable: !uploadReady }" @click="addfn(1)">
        添加BIM模型
      </div>
    </div>

    <div class="Implement-wrap">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">模型所属</span>
        <span class="title-05">模型名称</span>
        <span class="title-03">添加时间</span>
        <span class="title-04">操作</span>
      </div>

      <div
        class="Implement-list"
        v-for="(item, index) in listdata"
        :key="index"
      >
        <span class="title-01 textOverflowHide">{{ index + 1 }}</span>
        <span class="title-02 textOverflowHide">{{ item.Name }}</span>
        <span class="title-05 textOverflowHide">{{ item.ModelName }}</span>
        <span class="title-03 textOverflowHide">{{ item.CreateTime }}</span>
        <span class="title-04 textOverflowHide">
          <i v-if="item.FileType==1" @click="addfn(3, item)">{{item.IsSplit==1?'已拆分':'未拆分'}}</i>
          <i v-else @click="addfn(3, item)">预览</i>
          <!-- <i @click="addfn(2,item)">编辑</i> -->
          <i @click="delfn(item)">删除</i>
        </span>
      </div>
    </div>
    <div class="pages" v-if="total && total > pageSizes">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSizes"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <addModel
      v-if="addfalse"
      :addtitle="addtitle"
      :editdata="editdata"
      :oft="oft"
      @addfalsefn="addfalsefn"
      @GeteModelManagementList="GeteModelManagementList"
    >
    </addModel>
    <div class="model-wrap" v-if="activeurn">
      <div class="mtitle-w">
        <div class="mtw-text">模型详情</div>
        <div class="mtclose" @click="closeModel">&times;</div>
      </div>
      <div class="m-content">

        <modelView ref="mvref" @loaded="loaded" v-if = "FileType == 1"></modelView>
        <img v-if = "FileType == 2" :src="baseUrl + activeurn">
      </div>
    </div>
  </div>
</template>
<script>
import modelView from "@/components/modelView";
import { reactive, toRefs, onMounted, nextTick } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import addModel from "@/components/addModel.vue";
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {
  GetConvertTypes,
  getAccessToken,
  createBucket,
} from "@/js/uploadModel";
import { GeteModelManagementList, DeleteModelManagement ,SynchronousModel} from "@/js/modelApi";
export default {
  name: "",
  props: {},
  setup(props) {
    const state = reactive({
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addfalse: false,
      addtitle: "",
      listdata: [],
      editdata: {},
      pkpmToken: "",
      oft: [],
      uploadReady: false,
      mvref: "",
      activeurn: "",
      componentModel: [],
      activeItem:'',
      FileType:'',
      baseUrl:baseUrl,
      uploadid:''
    });
    const cdata = getStorage("bscdata");
    const gt = () => {
      // 获取 pkpm token
      getAccessToken().then((res) => {
        console.log(res);
        state.pkpmToken = res.data.access_token;
        GetConvertTypes(state.pkpmToken).then((res) => {
          state.oft = res.data.filetypes;
          createBucket(state.pkpmToken)
            .then((res2) => {
              state.uploadReady = true;
            })
            .catch((error) => {
              if (error.response.data.code == "BucketAlreadyExists") {
                state.uploadReady = true;
              }
            });
        });
      });
    };
    const initModel = (urn) => {
      console.log(state.mvref);
      state.mvref.modelInit([urn], "");
    

    };
    //递归数据
    const recursionData = (list, count) => {
      for (var i = 0; i < list.length; i++) {
        if (list[i].children != undefined && list[i].children.length > 0) {
          if (count < 4) {
            count++;
            recursionData(list[i].children, count);
            count--;
          }
          state.componentModel.push({
            ComponentId: list[i].id,
            ParentId: list[i].parentId,
            Name: list[i].name,
          });
        } else {
          state.componentModel.push({
            ComponentId: list[i].id,
            ParentId: list[i].parentId,
            Name: list[i].name,
          });
        }
      }
    };
    const setModelData = (list,rootid)=>{
      SynchronousModel({
        "InputComponentModel": list,
        "UserID": cdata.uid,
        "id":state.activeItem.ID,
        "rootId":rootid
      }).then((res) => {
          console.log(res);
          let {Code,Message} = res.data;
          if (Code == 1) {
           ElMessage({
              showClose: true,
              message: "模型数据同步成功",
              type: "success",
            });
            if(state.uploadid==3){
                methods.GeteModelManagementList();
            }
          } else {
            ElMessage({
              showClose: true,
              message: Message,
              type: "error",
            });
          }
        });
    }
    const methods = {
      loaded: (modelIndex, mApi) => {
        console.log(modelIndex, mApi);
        let model = mApi.getModelById(modelIndex);
        let modelTree = model.getModelTree();
        let rootId = modelTree.rootId;
        recursionData(modelTree.root.children, 0);
        setModelData(state.componentModel,rootId);
      },
      closeModel: () => {
        // 关闭模型弹框
        state.activeurn = "";
        state.mvref = "";
        state.FileType = "";
        state.activeItem = "";
      },
      // 添加弹框
      addfn: (id, item) => {
        if (state.uploadReady) {
          if (id == 1) {
            state.addtitle = "添加BIM模型";
            state.addfalse = !state.addfalse;
          } else if (id == 2) {
            state.addtitle = "编辑BIM模型";
            state.addfalse = !state.addfalse;

            state.editdata = item;
          } else if (id == 3) {
            state.uploadid=3
            console.log(item);
            state.activeurn = item.ImgUrl;
            state.FileType = item.FileType;
            state.activeItem = item;
            if(item.FileType == 1){
              nextTick(() => {
                initModel(state.activeurn);
              });
            }
          }
        }
      },
      //添加弹框 关闭弹框
      addfalsefn() {
        state.addfalse = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GeteModelManagementList();
      },

      // 获取模型列表
      GeteModelManagementList: () => {
        state.uploadid=''
        let datas = {
          ProjectID: cdata.pid,
          pageIndex: state.currentPage,
          pageSize: state.pageSizes,
        };
        GeteModelManagementList(datas).then((res) => {
          console.log(res, "list");
          if (res.data.Code == 1) {
            state.listdata = res.data.Data.data;
            state.total = res.data.Data.total.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
      // 删除模型
      delfn(item) {
        ElMessageBox.confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let datas = {
              ID: item.ID,
            };
            DeleteModelManagement(datas).then((res) => {
              if (res.data.Code == 1) {
                ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "success",
                });
                methods.GeteModelManagementList();
              } else {
                ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {
            return false;
          });
      },
    };

    onMounted(() => {
      methods.GeteModelManagementList();
      gt();
    });

    return {
      ...methods,
      ...toRefs(state),
    };
  },

  components: {
    addModel,
    modelView,
  },
};
</script>

<style lang='scss' scoped>
#modelView-c {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 107px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      &.disable {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
    span {
      i {
        cursor: pointer;
        &:nth-child(1) {
          color: #0083ff;
        }

        &:nth-child(2) {
          color: #e7111b;
        }
      }
    }
    .title-04 {
      display: flex;
      padding: 0;
      justify-content: space-between;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  .Implement-list {
    border-top: none;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
  }
  .title-01 {
    width: 4%;
  }
  .title-02 {
    width: 49%;
  }
  .title-03 {
    width: 19%;
  }
  .title-04 {
    width: 8%;
  }
    .title-05 {
    width: 20%;
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
  .model-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 400px;
    box-shadow: 0px 0px 7px #000;
    .mtitle-w {
      height: 40px;
      display: flex;
      background: #fff;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      justify-content: space-between;
      padding: 0 20px;
      .mtclose {
        padding: 0 10px;
        font-size: 28px;
        cursor: pointer;
      }
    }
    .m-content {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      bottom: 0;
      img{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: contain;
      }
    }
  }
}
</style>